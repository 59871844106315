import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RememberCards.module.css";
import { getGames, getgamesforRound, postScore } from "../apis/RoundGame";
import { getCurent } from "../apis/Round";
import { getProfile } from "../apis/Users";
import { getCurrentUser } from "../Services/authService";
import DrawerComponent from "../components/Drawer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 270,
  bgcolor: "#FFFFFF",
  borderRadius: 2,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const initialCards = [
  { "How many times VOIS was certified GPTW?": 4 },
  { "How many locations VOIS operates from?": 8 },
  { "When was VOIS brand launch and strategy?": 2020 },
  { "VOIS Employees number is above?": 31000 },
];

function RememberCard() {
  const [cards, setCards] = useState([]);
  const [score, setScore] = useState(0);
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [flippedIndices, setFlippedIndices] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setStartTime(Date.now());
    const flattenedCards = flattenArray(initialCards);
    setCards(shuffleArray(flattenedCards));
    setFlipped(new Array(flattenedCards.length).fill(false));
    setMatched(new Array(flattenedCards.length).fill(false));
  }, []);

  const flattenArray = (array) => {
    return array.reduce((acc, obj) => {
      const entries = Object.entries(obj).flat();
      return [...acc, ...entries];
    }, []);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleFlip = (index) => {
    if (matched[index] || flippedIndices.includes(index)) return;

    const newFlipped = [...flipped];
    newFlipped[index] = true;
    setFlipped(newFlipped);

    const newFlippedIndices = [...flippedIndices, index];
    setFlippedIndices(newFlippedIndices);

    if (newFlippedIndices.length === 2) {
      const [firstIndex, secondIndex] = newFlippedIndices;
      const firstCard = cards[firstIndex];
      const secondCard = cards[secondIndex];

      if (isMatch(firstCard, secondCard)) {
        setTimeout(() => {
          setMatched((prevMatched) =>
            prevMatched.map((state, i) =>
              i === firstIndex || i === secondIndex ? true : state
            )
          );
          setScore((prevScore) => prevScore + 1);
          setFlippedIndices([]);

          if (score + 1 === initialCards.length) {
            const timeSpent = Date.now() - startTime;
            setModalOpen(true);
            submitScore(timeSpent);
          }
        }, 1000);
      } else {
        setTimeout(() => {
          setFlipped((prevFlipped) =>
            prevFlipped.map((state, i) =>
              i === firstIndex || i === secondIndex ? false : state
            )
          );
          setFlippedIndices([]);
        }, 1000);
      }
    }
  };
  const isMatch = (card1, card2) => {
    for (const obj of initialCards) {
      const entries = Object.entries(obj).flat();
      if (
        entries.includes(card1) &&
        entries.includes(card2) &&
        card1 !== card2
      ) {
        return true;
      }
    }
    return false;
  };

  const handleReplay = () => {
    setScore(0);
    const flattenedCards = flattenArray(initialCards);
    setCards(shuffleArray(flattenedCards));
    setFlipped(new Array(flattenedCards.length).fill(false));
    setMatched(new Array(flattenedCards.length).fill(false));
    setFlippedIndices([]);
    setModalOpen(false);
    // Reset the start time when replaying
    setStartTime(Date.now());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitScore = async (timeSpent) => {
    try {
      getCurent().then(async (r) => {
        // First API call to get the game data and ID
        const gameResponse = await getgamesforRound({ roundID: r.data._id });
        const specificGame = gameResponse.data.find(
          (game) => game.gameName === "Flip card"
        ); // Replace with your condition
        if (!specificGame) {
          alert("Error: Game not found.");
          return;
        }

        const gameId = specificGame._id;
        getCurrentUser().then((token) => {
          getProfile({ mobileNO: token.mobileNO }).then(async (x) => {
            const scoreData = {
              user_id: x.data._id,
              game_id: gameId,
              round_id: r.data._id,
              score: score,
              time_spent: timeSpent,
            };

            // Check if all required data is present

            // Second API call to submit the score
            const response = await postScore(scoreData);
          });
        });
      });
    } catch (error) {
      console.error("Error saving score:", error);
    }
  };

  return (
    <div className={styles["main-div"]}>
      <div className={styles["full-page"]}>
        <div className={styles["header"]}>
          <DrawerComponent/>
          <p className={styles["score"]}>Score:  {score}</p>
        </div>
        <p className={styles["game-name"]}>VOIS Flip Cards</p>
        <Modal open={open} onClose={handleClose} disableAutoFocus>
          <Box
            sx={{ ...style, width: 250, paddingBottom: 40 }}
            textAlign='center'
          >
            <img
              className={styles["img-modal"]}
              src='/modalImage.webp'
              alt='Modal'
            />
            <p className={styles["desc-modal"]}>
              Exercise your memory by matching VOIS technologies together
            </p>
            <button
              className={styles["btn-modal"]}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              onPointerEnter={handleClose}
            >
              Start
            </button>
          </Box>
        </Modal>
        <div className={styles["main-body"]}>
          {cards.map((card, index) => (
            <div
              key={index}
              className={`${styles["card"]} ${
                flipped[index] ? styles["flipped"] : ""
              } ${matched[index] ? styles["matched"] : ""}`}
              onClick={() => handleFlip(index)}
              onPointerEnter={() => handleFlip(index)}
              style={{
                display: matched[index] ? "none" : "block",
                cursor: "pointer",
              }} // Hide matched cards
            >
              <div className={styles["card-inner"]}>
                <div className={styles["card-front"]}></div>
                <div className={styles["card-back"]}>
                  {flipped[index] ? card : "?"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal open={modalOpen} onClose={handleClose} disableAutoFocus>
        <Box
          sx={{ ...style, width: 285, paddingBottom: 40 }}
          textAlign='center'
        >
          <img className={styles["modal-image"]} src='/winner.webp' />
          <div className={styles["modal-winner"]}>Congratulations</div>
          <div>You've won!</div>
          <div className={styles["btns-modal"]}>
            {/* <button
              className={`${styles["btn"]} ${styles["back-button"]}`}
              onClick={handleReplay}
            >
              Play Again
            </button> */}
            <button
              className={`${styles["btn"]} ${styles["replay-button"]}`}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/home")}
              onPointerEnter={() => navigate("/home")}
            >
              Go to home page
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default RememberCard;
