import AxiosInstance from "./config";

export const startGame = (
  obj = {
    memberID: "66b51f9506904bf6243a1826",
    gameID: "66b611fb2c6f8905f2aec7d2",
  }
) => {
  return AxiosInstance.post("/qr/start", obj);
};

export const updateQRs = (
  obj = { memberID: "66b51f9506904bf6243a1826", QR: "https://www.github.com/" }
) => {
  return AxiosInstance.post("/qr/update", obj);
};

export const checkIfQRScanned = (
  obj = { memberID: "66b51f9506904bf6243a1826", QR: "https://www.test.com/" }
) => {
  return AxiosInstance.post("/qr/check", obj);
};

export const getRemaining = (obj) => {
  return AxiosInstance.post("/qr/get-remaining", obj);
};

export const setScore = (
  obj = { memberID: "66b51f9506904bf6243a1826", score: 15 }
) => {
  return AxiosInstance.post("/qr/set-score", obj);
};

export const calculateResults = (
  obj = { memberID: "66b51f9506904bf6243a1826" }
) => {
  return AxiosInstance.post("/qr/final-results", obj);
};
