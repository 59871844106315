// ProtectedRoute.js
import React from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const token = sessionStorage.getItem("token"); // Replace this with your authentication logic
  const location = useLocation();

  if (!token && window.location.pathname !== "/entrance") {
    return <Navigate replace='/home' />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
