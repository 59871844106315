import React from "react";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import {
  generateSecret,
  CompactEncrypt,
  compactDecrypt,
  importSPKI,
  importPKCS8,
} from "jose";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Private variables for cookies
const COOKIE_NAME = "token";
let PRIVATE_KEY;
let PUBLIC_KEY;
let JWTKey;
export async function fetchKeys() {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}keys`); // Your API endpoint to get keys
  return response.data.privateKeyPem;
}

// Load and convert the PEM public key
const loadPublicKey = async () => { 
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}keys`); // Fetch the PEM public key file
    const publicKeyPem = response.data.publicKeyPem;
    PUBLIC_KEY = await importSPKI(publicKeyPem, "RSA-OAEP"); // Convert PEM to JWK
  } catch (error) {
    console.error("Error loading public key:", error);
  }
};

// Load and convert the PEM private key
const loadPrivateKey = async () => {
  try {
    const response = await fetchKeys(); // Fetch the PEM private key file
    const privateKeyPem = response;

    PRIVATE_KEY = await importPKCS8(privateKeyPem, "RSA-OAEP"); // Convert PEM to JWK
  } catch (error) {
    console.error("Error loading private key:", error);
  }
};

// Get credentials from settings file
const getCredentials = async () => {
  return fetch("/appSettings.json")
    .then((res) => res.json())
    .then((result) => result)
    .catch(console.log);
};
let tokenCred = await getCredentials();
const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: tokenCred.Authority,
      clientId: tokenCred.Client_ID,
      postLogoutRedirectUri: tokenCred.VISITVOIS_BASE_URL,
      redirectUri: `${tokenCred.VISITVOIS_BASE_URL}/admin/home`,
      validateAuthority: true,
      navigateToLoginRequestUrl: false,
    },
    system: {
      logger: new Logger(
        (logLevel, message, containsPii) => {
          console.log(`MSAL Log - ${logLevel}: ${message}`);
        },
        {
          level: LogLevel.Verbose,
          piiLoggingEnabled: false,
        }
      ),
    },
    cache: {
      cacheLocation: "sessionStorage", // Temporarily use sessionStorage, will be cleared
      storeAuthStateInCookie: false, // No cookies
    },
  },
  {
    scopes: ["openid"],
  },
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}/auth.html`,
  }
);
export const fetchUserData = async () => {
  await loadPublicKey(); // Load the public key
  await loadPrivateKey(); // Load the private key
  debugger;
  const account = authProvider.getAccount();

  if (account) {
    try {
      // Attempt to acquire token silently
      const tokenResponse = await authProvider.acquireTokenSilent({
        scopes: ["openid"],
        loginHint: account.userName, // Use the loginHint for silent token request
      });

      // Encrypt the ID token before storing it in a cookie
      const encoder = new TextEncoder();
      const encodedIdToken = encoder.encode(tokenResponse.idToken.rawIdToken);

      const jwe = await new CompactEncrypt(encodedIdToken)
        .setProtectedHeader({
          alg: "RSA-OAEP",
          enc: "A256GCM",
        })
        .encrypt(PUBLIC_KEY);

      // Store the encrypted token in a cookie
      sessionStorage.clear();
      sessionStorage.setItem(COOKIE_NAME, jwe);
    } catch (error) {
      // Handle errors (e.g., token acquisition failed)
      console.error("Token acquisition error:", error);

      // Optionally redirect to login page if silent token acquisition fails
      await authProvider.logout();
    }
  }
  debugger;
};
// Initialize auth provider and handle token encryption
const getAll = async () => {
  await loadPublicKey(); // Load the public key
  await loadPrivateKey(); // Load the private key

  return authProvider;
};
export const getIdToken = async () => {
  await loadPrivateKey(); // Load the private key

  const encryptedToken = sessionStorage.getItem("token");
  //console.log(encryptedToken)
  if (!encryptedToken) return null;

  // Decrypt the token using the private key
  try {
    const { plaintext } = await compactDecrypt(encryptedToken, PRIVATE_KEY);
    const decoder = new TextDecoder();
    //console.log(decoder.decode(plaintext));
    return jwtDecode(decoder.decode(plaintext));
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

export default getAll().then((r) => r);
