import { jwtDecode } from "jwt-decode";
import { fetchKeys, getIdToken } from "../AdminLogin/Auth/authProvider.js";
import http, { setJwt } from "./httpService.js";
import { Buffer } from "buffer";
import { EncryptJWT, generateKeyPair, importPKCS8, jwtDecrypt } from "jose";
import forge from "node-forge";
import axios from "axios";
const apiEndpoint = process.env.REACT_APP_SERVER_URL + "auth";
let tokenKey;
export const getCredentials = async () =>
  fetch("/appSettings.json")
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(console.log);

export const login = async (loginUser, recaptchaToken) => {
  const { data: jwt } = await http.post(
    apiEndpoint,
    {
      email: loginUser.email,
    },
    { headers: { RecaptchaHeader: recaptchaToken } }
  );
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.setItem(tokenKey, jwt);
};

export const loginWithJwt = async (jwt) => {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.setItem(tokenKey, jwt);
};

export async function getCurrentUser() {
  try {
    const decoded = await getIdToken();

    return decoded;
  } catch (error) {
    console.error("Error decrypting user token:", error);
  }
}

export async function getJwt() {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  return localStorage.getItem(tokenKey);
}

export const logout = async () => {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.removeItem(tokenKey);
};

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
