import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

import "./index.css";

export default function CustomSeparator({ roundName }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/admin/home");
  }

  const breadcrumbs = [
    <Link
      className='rounds-nav-link'
      zIndex={100}
      underline='hover'
      key='1'
      color='#0D0D0D'
      onClick={handleClick}
    >
      Rounds
    </Link>,
    <Link
      className='round-link-adimn-view'
      underline='none'
      key='2'
      color='#0D0D0D'
      zIndex={100}
      fontWeight={700}
      textTransform={"capitalize"}
    >
      {roundName}
    </Link>,
  ];

  return (
    <Breadcrumbs
      style={{ paddingBottom: 25, paddingLeft: 80 }}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}
