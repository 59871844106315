import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import House from "../../assets/House.webp";
import UserCircle from "../../assets/UserCircle.webp";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { isSafari } from "react-device-detect";

const DrawerComponent = ({ color }) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  let navigate = useNavigate();
  const DrawerList = (
    <Box sx={{ width: 250 }} role='presentation' onClick={toggleDrawer(false)}>
      <List>
        <Grid display={"flex"} justifyContent='flex-end' sx={8} mx={2} my={4}>
          <IconButton>
            {" "}
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Grid>
        <ListItem disablePadding>
          <ListItemButton
            style={{ cursor: "pointer" }}
            onPointerEnter={() =>
              window.location.pathname.includes("hub2")
                ? (window.location.href = "/home")
                : navigate("/home")
            }
            onClick={() =>
              window.location.pathname.includes("hub2")
                ? (window.location.href = "/home")
                : navigate("/home")
            }
          >
            <ListItemIcon>
              <img src={House} />
            </ListItemIcon>
            <ListItemText sx={{ color: "black" }} primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            style={{ cursor: "pointer" }}
            onPointerEnter={() => (window.location.href = "/profile")}
            onClick={() => (window.location.href = "/profile")}
          >
            <ListItemIcon>
              <img src={UserCircle} />
            </ListItemIcon>
            <ListItemText primary={"Profile"} sx={{ color: "black" }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <div>
      <IconButton
        style={{ cursor: "pointer" }}
        onClick={toggleDrawer(true)}
        onPointerEnter={isSafari ? toggleDrawer(true) : null}
        sx={{
          color: color ? color : "white",
          borderRadius: "28px",
          border: color ? `1px solid ${color}` : "1px solid white",
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
export default DrawerComponent;
