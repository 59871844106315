import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import rafiki from "../assets/rafiki.webp";

const NoActiveRounds = () => {
  return (
    <Box sx={{ flexGrow: 1 }} my={1} alignItems='center'>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        rowGap={2}
        my={6}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img src={rafiki} style={{ marginTop: "50px" }} />
        <h4>Ooopss!</h4>
        <Grid textAlign={"center"} my={2} mx={6} xs={7}>
          <div style={{ width: "250px" }}>
            Unfortunately, there are currently no active rounds taking place at
            the moment
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoActiveRounds;
