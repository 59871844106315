import React, { useEffect } from "react";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import authProvider from "./Auth/authProvider";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../Services/authService";
import styles from "./AdminLogin.module.css"

function AdminLogin() {
  const history = useNavigate();
  let [cred, setCred] = React.useState();
  useEffect(() => {
    authProvider.then((r) => {
      setCred(r);
    });
    getCurrentUser().then((r) => {
      if (r != null) {
        history("/admin/home");
      }
    });
  }, []);
  return cred ? (
    <AzureAD provider={cred}>
      {({ login, logout, authenticationState, error }) => {
        const isInProgress =
          authenticationState === AuthenticationState.InProgress;
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;
        const isUnauthenticated =
          authenticationState === AuthenticationState.Unauthenticated;
        if (isAuthenticated) {
          return (
            <React.Fragment>
              {() => {
                history("/admin/home");
              }}
            </React.Fragment>
          );
        } else if (isUnauthenticated) {
          return (
            <div className="container-fluid p-0">
              <div className="maindiv row">
                {error ? (
                  <h1
                    className=" focus-in-contract-bck text-white mb-5 login-title "
                    style={{ textAlign: "center", marginTop: 50 }}
                  >
                    <span>
                      Apologies, you are not authorized to access this platform
                    </span>
                  </h1>
                ) : (
                  <div
                    className={styles.loginContainerDiv}
                  >
                    <div
                      className={styles.VOISLogo}
                    >
                      VOIS VISIT
                    </div>
                    <div
                      className={styles.contentContainer}
                    >
                      <div
                        className={styles.leftContainer}
                      >
                        <h1 className={styles.welcomeToVISIT}>
                          Welcome to VOIS Visit
                        </h1>
                        <h6
                          className={styles.welcomeToVISITDesc}
                        >
                          Welcome to VOIS Admin View, where you can centrally
                          manage your rounds and control all your games and
                          activities.
                        </h6>
                        <button
                          className={styles.loginBtn}
                          onClick={async () => {
                            login();
                          }}
                        >
                          Log in as Vodafone user
                        </button>
                        <div className={styles.linksDiv}>
                          <div style={{ marginRight: 50 }}>
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/Employee-Privacy-Statement.aspx?csf=1&e=PahNAV"
                            >
                              Employee Privacy Statement
                            </a>
                            <img src="/assets/System.png" />
                          </div>

                          <div>
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/External-Workforce.aspx?csf=1&e=hMss8f"
                            >
                              Contractor Privacy Statement
                            </a>
                            <img src="/assets/System.png" />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "0px 2px 8px 0px #00000029",
                          borderRadius: "25px",
                          height: "90%",
                        }}
                      >
                        <img src="/assets/pana.png" width="80%" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  ) : (
    <div></div>
  );
}

export default AdminLogin;
