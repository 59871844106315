import React, { useEffect, useState } from "react";
import "./slideshow.css";

import basketball from "../../assets/basketball2.webp";
import treasureHunt from "../../assets/treasure-hunt2.webp";
import virtualTour from "../../assets/virtual-tour2.webp";
import comingSoon from "../../assets/comingSoon.webp";
import flipCards from "../../assets/flipCardRed.webp";
import { useNavigate } from "react-router-dom";
import { getGamesByRoundID } from "../../apis/RoundGame";
import { getCurent } from "../../apis/Round";

const moveToLast = (arr, targetName) => {
  const index = arr.findIndex((obj) => obj.gameName === targetName);
  if (index !== -1) {
    const [item] = arr.splice(index, 1);
    arr.push(item);
  }
};

const Slideshow = () => {
  let touchStart;
  let navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [games, setGames] = useState([]);
  const imgs = [
    { img: flipCards, url: "/remembercard" },
    { img: treasureHunt, url: "/treasure-hunt" },
    { img: basketball, url: "/basketball" },
    { img: virtualTour, url: "/virtual-tour" },
  ];
  const [isSwiping, setIsSwiping] = useState(false);
  let touchStartX = 0;
  let touchEndX = 0;
  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].clientX;
    const swipeDistance = touchStartX - touchEndX;

    // If swipe distance is significant, it's a swipe, not a tap.
    if (Math.abs(swipeDistance) > 30) {
      setIsSwiping(true);
      // Handle slide change logic here based on swipe direction.
      if (swipeDistance > 0) {
        // Swiped left
        setIndex((prev) => Math.min(prev + 1, games.length - 1));
      } else {
        // Swiped right
        setIndex((prev) => Math.max(prev - 1, 0));
      }
    } else {
      setIsSwiping(false);
    }
  };

  React.useEffect(() => {
    setIndex(0);
    getCurent().then((r) => {
      getGamesByRoundID({ roundID: r.data._id }).then((res) => {
        setGames(res.data.filter((r) => r.gameName != "Survey"));
      });
    });
  }, []);

  React.useEffect(() => {
    moveToLast(games, "Basketball");
  }, [games]);

  const handleImages = (gameName) => {
    if (gameName == "Flip card")
      return { img: flipCards, url: "/remembercard", name: gameName };
    if (gameName == "Treasure hunt")
      return { img: treasureHunt, url: "/treasure-hunt", name: gameName };
    if (gameName == "Basketball")
      return { img: basketball, url: "/basketball", name: gameName };
    if (gameName == "Virtual Tour")
      return { img: virtualTour, url: "/virtual-tour", name: gameName };
  };

  return (
    <div className='slideshow'>
      <div
        className='slideshowSlider'
        style={{ transform: `translate3d(${-index * 90}%, 0, 0)` }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {games.map((img, idx) => (
          <div
            className={img.isActive ? "slide" : "slide-deactivated"}
            key={idx}
            onClick={() =>
              !isSwiping && index === idx && img.isActive
                ? navigate(handleImages(img.gameName).url)
                : null
            }
          >
            <div
              className='comingSoon'
              style={
                img.gameName === "Basketball"
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <img src={comingSoon} />
            </div>
            <img
              src={handleImages(img.gameName).img}
              className={index === idx ? "img-active" : "img-slide"}
            />
          </div>
        ))}
      </div>

      <div className='slideshowDots'>
        {games.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${
              index === idx
                ? " active"
                : index - 1 === idx || index + 1 === idx
                ? " abActive"
                : ""
            }`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
