import React, { useEffect, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useFetcher, useNavigate } from "react-router-dom";
import ButtonRounded from "../components/ButtonRounded";
import winner from "../assets/winner.webp";
import QRCode from "../assets/QRCode.webp";
import points from "../assets/points.webp";
import time from "../assets/time.webp";
import DrawerComponent from "../components/Drawer";
import { calculateResults } from "../apis/ScannedQRs";
import { getProfile } from "../apis/Users";
import { getCurrentUser } from "../Services/authService";

const Results = () => {
  const [score, setScore] = useState(0);
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  useEffect(() => {
    getCurrentUser().then((token) => {
      getProfile({ mobileNO: token.mobileNO }).then((res) => {
        setName(res.data.name);
        setMobileNo(token.mobileNO);
      });
      calculateResults({ mobileNO: token.mobileNO }).then((res) =>
        setScore(res.data)
      );
    });
  }, []);

  const renderCard = (icon, value, label) => {
    return (
      <Card
        sx={{
          paddingTop: "10px",
          width: "100px",
          display: "flex",
          height: "110px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 2px 8px 0px #00000029",
        }}
      >
        <img src={icon} style={{ marginBottom: "20px" }} />
        <Grid textAlign={"center"}>{value}</Grid>
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>{label}</span>
      </Card>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }} my={1} alignItems='center'>
      <Grid mx={2}>
        <DrawerComponent color={"black"} />
      </Grid>
      <Grid
        my={4}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
      >
        <img src={winner} />
        <h4 style={{ textTransform: "capitalize" }}>{name}</h4>
        <span>Phone number:{mobileNo}</span>

        <Grid
          my={4}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"space-between"}
          textAlign={"center"}
          sx={{ columnGap: "20px" }}
        >
          {renderCard(time, `${score.duration} mins`, "Time")}
          {renderCard(points, `${score.finalScore}`, "Points")}
          {renderCard(QRCode, `${score.numberOfScanned}`, "QR Codes")}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Results;
