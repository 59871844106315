import React, { useEffect, useState } from "react";
import Hello from "../assets/Hello.webp";
import Egypt from "../assets/Egypt.webp";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";
import { getCodebyMobile } from "../apis/Verfication";
import { FormHelperText } from "@mui/material";
import { isMemberOnCurrentRound } from "../apis/Users";
import { getCurrentRound } from "../apis/Round";

const Entrance = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("");

  const [mobileNO, setMobileNO] = useState();
  const [validNumber, setValidNumber] = useState(false);
  const [isVisitorOnCurrentRound, setIsVisitorOnCurrentRound] = useState();

  const isUserOnRound = (obj) => {
    getCurrentRound().then((res) => {
      const roundID = res.data._id;
      isMemberOnCurrentRound({ roundID, mobileNO })
        .then((rsp) => {
          if (rsp.data) handleAddUser(obj);
        })
        .catch((error) => {
          setIsVisitorOnCurrentRound("Not registered");
        });
    });
  };

  const handleAddUser = (obj) => {
    getCodebyMobile(obj.mobileNO).then((res) => {
      if (res.status == 200) navigate("/otp", { state: { obj } });
    });
  };

  useEffect(() => {
    getCurrentRound()
      .then((res) => {})
      .catch(() => {
        navigate("/no-rounds");
      });
  }, []);

  useEffect(() => {
    const isItValid = mobileNO?.length === 10 && !/\D/.test(mobileNO);
    setValidNumber(isItValid);
    setIsVisitorOnCurrentRound(isItValid);
  }, [mobileNO]);

  return (
    <Box sx={{ flexGrow: 1 }} my={4}>
      <Grid
        direction={"column"}
        container
        margin={0}
        rowGap={5}
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img src={Hello} />

        <FormControl sx={{ m: 1, width: "80%" }} variant='outlined'>
          <OutlinedInput
            size='small'
            value={name}
            sx={{ fontSize: "12px" }}
            onChange={(e) => setName(e.target.value)}
            placeholder='Please enter your name here'
            id='name'
            aria-describedby='outlined-name-helper-text'
            inputProps={{
              "aria-label": "name",
            }}
            required
          />
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: "80%" }} variant='outlined'>
            <OutlinedInput
              error={!/^\d{10}$/.test(mobileNO)}
              helperText={
                !/^\d{10}$/.test(mobileNO) ? "Invalid Phone Number" : ""
              }
              size='small'
              startAdornment={
                <InputAdornment position='start'>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "2px",
                    }}
                  >
                    <img src={Egypt} /> (+20)
                  </div>
                  {/* <Divider orientation="vertical" flexItem /> */}
                </InputAdornment>
              }
              value={mobileNO}
              sx={{ fontSize: "12px" }}
              onChange={(e) => {
                setMobileNO(e.target.value);
              }}
              placeholder='(e.g. 10123456789)'
              id='mobileNO'
              aria-describedby='outlined-name-helper-text'
              inputProps={{
                "aria-label": "name",
              }}
              required
            />
          </FormControl>

          {!validNumber && mobileNO && (
            <FormHelperText
              style={{
                color: "#E60000",
                alignSelf: "start",
                paddingLeft: "10%",
              }}
            >
              Invalid phone number
            </FormHelperText>
          )}
          {isVisitorOnCurrentRound === "Not registered" && (
            <FormHelperText
              style={{
                color: "#E60000",
                alignSelf: "start",
                paddingLeft: "10%",
              }}
            >
              You're not registered on this round
            </FormHelperText>
          )}
        </div>
        <ButtonRounded
          value={name && validNumber}
          text={"Next"}
          onClick={() => {
            isUserOnRound({ name, mobileNO, status: "unActive" });
          }}
        />
      </Grid>
    </Box>
  );
};

export default Entrance;
