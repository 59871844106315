import AxiosInstance from "./config";

export const addRound = (
  obj = {
    name: "round10",
    startDate: new Date(),
    endDate: new Date("2024-10-09T12:00:00Z"),
    status: "soon",
  }
) => {
  return AxiosInstance.post("/round/add", obj);
};

export const editRound = (obj) => {
  return AxiosInstance.post("/round/edit", obj);
};
export const getCurrentRound = () => {
  return AxiosInstance.get("/round/get-current-external");
};

export const getRounds = () => {
  return AxiosInstance.get("/round/rounds");
};

export const getRound = (obj) => {
  return AxiosInstance.post("/round/rounduser", obj);
};
export const getCurent = () => {
  return AxiosInstance.get("/round/get-current");
};
export const getCurentExternal = () => {
  return AxiosInstance.get("/round/get-current-external");
};
