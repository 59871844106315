import React, { useState } from "react";

import StarStruck from "../assets/Star-struck.gif";
import Pensive from "../assets/Pensive.gif";
import sent from "../assets/sent.webp";
import Rage from "../assets/Rage.gif";
import Smile from "../assets/Smile-with-big-eyes.gif";
import Happy from "../assets/Slightly-happy.gif";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCurent } from "../apis/Round";
import { saveSurvey } from "../apis/Survey";
import BasicModal from "../components/ModalMessage";
import { getCurrentUser } from "../Services/authService";

import styles from "./index.module.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "black",
  },
}));

const NextBtn = styled(IconButton)({
  backgroundColor: "#E60000",
  color: "white",
  borderRadius: "28px",
  border: "1px solid #E60000",

  "&:active": {
    backgroundColor: "#E60000",
    color: "white",
    borderRadius: "28px",
    border: "1px solid #E60000",
  },
  "&:focus": {
    backgroundColor: "#E60000",
    color: "white",
    borderRadius: "28px",
    border: "1px solid #E60000",
  },
});

const Survey = () => {
  const [steps, setSteps] = useState(0);
  let navigate = useNavigate();
  const [results, setResults] = useState(["", "", "", "", ""]);

  const [openMsg, setOpenMSg] = useState(false);
  const emotions = [
    { icon: StarStruck, text: "Strongly Agree" },
    { icon: Smile, text: "Agree" },
    { icon: Happy, text: "Neutral" },
    { icon: Pensive, text: "Disagree" },
    { icon: Rage, text: "Strongly Disagree" },
  ];
  const handleResults = (result) => {
    let temp = [];

    results.map((res, index) => {
      if (index == steps) temp.push(result);
      else temp.push(res);
    });

    setResults(temp);
  };

  const handleAnswer = (question, answer, steps) => {
    getCurrentUser().then((token) =>
      getCurent().then((r) => {
        saveSurvey({
          type: "final",
          name: token.name,
          round_id: r.data._id,
          mobileNO: token.mobileNO,
          question: question,
          answer: answer,
        })
          .then(() => {
            if (steps == 4) {
              setOpenMSg(true);
            }
          })
          .catch(() => {
          });
      })
    );
  };

  const questions = [
    "My visit to VOIS was a pleasant experience",
    "Did you like the activities during the visit?",
    "Would you be interested to join VOIS in the future?",
    "Overall, what affect would you say your visit to our office had on you?",
    "Was there anything specific that you liked / disliked or that we can do to make any future visit to our office more pleasant?",
  ];
  return (
    <>
      <div className={styles.bgColor}></div>
      <div className={styles.container}>
        <Box
          sx={{ flexGrow: 1, height: "75vh", minHeight: 600 }}
          display="flex" flexDirection={"column"}  justifyContent={"center"}
        >
          {openMsg && (
            <BasicModal
              onClose={() => navigate("/home")}
              img={<img width={"47px"} height={"47px"} src={sent} />}
              text="Your response has been successfully submitted. Thank you for your feedback."
            />
          )}
          <Grid display={"flex"} justifyContent="flex-end" sx={8} mx={2}>
            <IconButton onClick={() => navigate("/home")}>
              <HighlightOffOutlinedIcon style={{ color: "white" }} />
            </IconButton>
          </Grid>
          <Grid
            mx={10}
            justifyContent="center"
            sx={8}
            direction="column"
            display="flex"
          >
            <div
              style={{
                color: "white",
                marginBottom: "20px",
                alignSelf: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{steps + 1} </span> of 5
            </div>
            <BorderLinearProgress
              variant="determinate"
              value={(steps + 1) * 20}
            />
          </Grid>
          <Grid
            mx={4}
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
            mt={3}
          >
            <h4 className={styles.question}>{questions[steps]}</h4>
          </Grid>
          {steps == 4 ? (
            <Grid
              container
              spacing={1}
              alignItems="center"
              my={4}
              justifyContent="center"
            >
              <FormControl
                sx={{ m: 1, width: "90%", ml: 2 }}
                variant="outlined"
              >
                <OutlinedInput
                  value={results[4]}
                  multiline
                  rows={5}
                  onChange={(e) => handleResults(e.target.value)}
                  placeholder="Please type your feedback here"
                  id="Thoughts"
                  aria-describedby="outlined-Thoughts-helper-text"
                  inputProps={{
                    "aria-label": "results",
                  }}
                  sx={{
                    border: "none",
                    borderRadius: 2,
                    backgroundColor: "white",
                    input: {
                      "&::placeholder": {
                        color: "#BEBEBE",
                      },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid black", 
                    },
                  }}
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid
              container
              spacing={1}
              alignItems="baseline"
              justifyContent="center"
            >
              {emotions.map((emo, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    my={2}
                    mx={1}
                    xs={3}
                    textAlign={"center"}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleResults(emo.text)}
                    onPointerEnter={() => handleResults(emo.text)}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "100px",
                        justifyContent: "center",
                        boxShadow: "0px 2px 8px 0px #00000029",
                        border:
                          emo.text == results[steps] ? "2px solid black" : "",
                      }}
                    >
                      <img
                        src={emo.icon}
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Card>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {emo.text}
                    </span>
                  </Grid>
                );
              })}
            </Grid>
          )}
          
        </Box>
        <Grid
            container
            alignContent="center"
            px={2}
            pb={2}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <IconButton
              onClick={() => setSteps(steps - 1)}
              onPointerEnter={() => setSteps(steps - 1)}
              sx={{
                color: "white",
                cursor: "pointer",
                borderRadius: "28px",
                border: "1px solid white",
                visibility: steps == 0 ? "hidden" : "visible",
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            {steps == 4 ? (
              <button
                className={styles.submitBtn}
                disabled={!results[4]}
                value={results[4]}
                onClick={() => {
                  handleAnswer(questions[steps], results[steps], steps);
                }}
              >
                Submit
              </button>
            ) : (
              <NextBtn
                onClick={() => {
                  handleAnswer(questions[steps], results[steps]);
                  setSteps(steps + 1);
                }}
                onPointerEnter={() => {
                  handleAnswer(questions[steps], results[steps]);
                  setSteps(steps + 1);
                }}
                disabled={!results[steps]}
                sx={{
                  borderRadius: "28px",
                  border: results[steps]
                    ? "1px solid white"
                    : "1px solid #ffffff8c",
                  visibility: steps == 4 ? "hidden" : "visible",
                }}
                style={{
                  color: results[steps] ? "white" : "#ffffff8c",
                  backgroundColor: "transparent",
                }}
              >
                <NavigateNextIcon />
              </NextBtn>
            )}
          </Grid>
      </div>
    </>
  );
};

export default Survey;
