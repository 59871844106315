import AxiosInstance from "./config";

export const addMember = (obj) => {
  return AxiosInstance.post("/member/add", obj);
};
export const getToken = (obj) => {
  return AxiosInstance.post("/member/gettoken", obj);
};

export const editMember = (obj) => {
  return AxiosInstance.post("/member/edit", obj);
};

export const getProfile = (obj) => {
  return AxiosInstance.post("/member/profile", obj);
};

export const isMemberOnCurrentRound = (obj) => {
  return AxiosInstance.post("/member/member-on-round", obj);
};
