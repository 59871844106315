import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./VirtualTourLanding.module.css";

function VirtualTourLanding() {
  const navigate = useNavigate();

  useEffect(() => {
    const handlemobileChange = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        document.getElementById("wivamobilebtn").click();
      } else if (window.matchMedia("(orientation: landscape)").matches) {
        if (document.getElementById("wivamobile")?.className.includes("show")) {
          document.getElementById("wivamobilebtn").click();
        }
      }
    };
    window.addEventListener("resize", handlemobileChange);
    handlemobileChange();
    return () => {
      if (document.getElementById("wivamobile")?.className.includes("show")) {
        document.getElementById("wivamobilebtn").click();
      }
      window.removeEventListener("resize", handlemobileChange);
    };
  }, []);

  return (
    <div className='container-fluid p-0'>
      <div className={styles["main-div"] + " row"}>
        <div className={styles["screen-body"] + " col-md-12"}>
          <img
            src='/assets/virtualTourBack.webp'
            className={styles["back-btn"]}
            onClick={() => {
              navigate("/home");
            }}
            alt='Back'
          />
          <div className={styles["main-div-header-text"]}>
            <div className={styles["screen-header-main-text"]}>
              Where are you?
            </div>
            <div className={styles["screen-header-submain-text"]}>
              Choose a building to get to know more about VOIS Facilities
            </div>
          </div>
          <div className={styles["buildings-boxes"]}>
            <div className={styles["building-box-card-dimmed"]}>
              <img
                src='/assets/Buildings/vhub1.webp'
                className={styles["building-box-card-img"]}
                alt='Vhub1'
              />
              <span className={`${styles["btn-disabled"]}`} disabled>
                Vhub1
              </span>
            </div>
            <div
              className={styles["building-box-card"]}
              onClick={() => {
                window.open("/hub2", "_blank");
              }}
            >
              <img
                src='/assets/Buildings/vhub2.webp'
                className={styles["building-box-card-img"]}
                alt='Vhub2'
              />
              <span className={styles["btn"]}>Vhub2</span>
            </div>
            <div
              className={styles["building-box-card"]}
              onClick={() => {
                window.open("/dallah", "_blank");
              }}
            >
              <img
                src='/assets/Buildings/dallah.webp'
                className={styles["building-box-card-img"]}
                alt='Dallah'
              />
              <span className={`${styles["btn"]}`}>Dallah</span>
            </div>
            <div className={styles["building-box-card-dimmed"]}>
              <img
                src='/assets/Buildings/zayan.webp'
                className={styles["building-box-card-img"]}
                alt='Zyan'
              />
              <span className={` ${styles["btn-disabled"]}`} disabled>
                Zyan
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualTourLanding;
