import React, { useState, useEffect } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Clap from "../assets/Clap.gif";
import CrossMark from "../assets/Cross-mark.gif";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ButtonRounded from "../components/ButtonRounded";
import { setScore } from "../apis/ScannedQRs";
import BasicModal from "../components/ModalMessage";
import { getCurrentUser } from "../Services/authService";

const Questions = () => {
  let navigate = useNavigate();
  const [result, setResult] = useState();
  const [status, setStatus] = useState("");

  const [open, setOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setOpen(false);
  }, []);
  const handleResults = (answer) => {
    setResult(answer);
    if (questions[id].correct == answer) {
      getCurrentUser().then((token) => {
        setScore({ mobileNO: token.mobileNO, score: questions[id].score });
      });
      setStatus("correct");
    } else {
      getCurrentUser().then((token) => {
        setScore({ mobileNO: token.mobileNO, score: 0 });
      });
      setStatus("wrong");
    }
  };

  const handlesubmit = () => {
    setOpen(true);
  };

  const questions = [
    {
      id: 0,
      text: "Where was VOIS first established?",
      answers: ["India", "Hungray", "Egypt", "Romania"],
      correct: "Egypt",
      score: 10,
    },
    {
      id: 1,
      text: "How many times VOIS was certified GPTW?",
      answers: ["1", "2", "3", "4"],
      correct: "4",
      score: 10,
    },
    {
      id: 2,
      text: "How many locations VOIS operates from?",
      answers: ["4", "6", "8", "10"],
      correct: "8",
      score: 10,
    },
    {
      id: 3,
      text: "Which of those is one of VOIS Services?",
      answers: ["Technology", "Care", "Networks", "All of them"],
      correct: "All of them",
      score: 10,
    },
    {
      id: 4,
      text: "When was VOIS brand launch and startegy?",
      answers: ["2018", "2020", "2022", "None of the mentioned"],
      correct: "2020",
      score: 10,
    },
    {
      id: 5,
      text: "VOIS Employees number is above?",
      answers: ["25,000", "29,000", "31,000", "40,000"],
      correct: "31,000",
      score: 10,
    },
    {
      id: 6,
      text: "When was the new commercial model launched?",
      answers: ["2022", "2024", "2023", "2021"],
      correct: "2024",
      score: 10,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }} my={1} alignItems='center'>
      {status == "correct" && open && (
        <BasicModal
          img={<img width={"52px"} height={"52px"} src={Clap} />}
          heading='wohoooo'
          text='you have answered correctly!'
          onClose={() => navigate("/scan")}
        />
      )}

      {status == "wrong" && open && (
        <BasicModal
          img={<img width={"52px"} height={"52px"} src={CrossMark} />}
          heading='Oooopsss'
          text='Wrong answer!'
          onClose={() => navigate("/scan")}
        />
      )}
      <Grid display={"flex"} justifyContent='flex-end' sx={8} mx={2} my={1}>
        <IconButton onClick={() => navigate("/home")}>
          {" "}
          <HighlightOffOutlinedIcon />
        </IconButton>
      </Grid>

      <Grid
        mx={2}
        my={2}
        alignItems={"center"}
        display={"flex"}
        textAlign={"center"}
        flexDirection={"column"}
        sx={{ rowGap: "20px" }}
      >
        <Grid my={4}>{questions[id].text}</Grid>
        {questions[id].answers.map((answer) => {
          return (
            <Card
              onClick={() => handleResults(answer)}
              onPointerEnter={() => handleResults(answer)}
              sx={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                width: "90%",
                height: "66px",
                justifyContent: "center",
                boxShadow: "0px 2px 8px 0px #00000029",
                border: answer == result ? "2px solid #E60000" : "",
              }}
            >
              {answer}
            </Card>
          );
        })}
        <Grid my={4}>
          <ButtonRounded
            value={result}
            text={"Submit"}
            onClick={() => handlesubmit()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Questions;
