import React, { useEffect, useState } from "react";
import styles from "./Landing.module.css";
import { useNavigate } from "react-router-dom";
import Slideshow from "../components/SlideShow";
import games from "../assets/games.webp";
import surveyImg from "../assets/survey.webp";
import notes from "../assets/notes.webp";
import ButtonRounded from "../components/ButtonRounded";
import DrawerComponent from "../components/Drawer";
import { getCurrentUser } from "../Services/authService";
import { getCurent } from "../apis/Round";
import { getGamesByRoundID } from "../apis/RoundGame";

function Landing() {
  let navigate = useNavigate();
  const [name, setName] = useState();
  const [survey, setSurvey] = useState([]);
  useEffect(() => {
    getCurrentUser().then((r) => setName(r.name));
    getCurent().then((r) => {
      getGamesByRoundID({ roundID: r.data._id }).then((res) => {
        setSurvey(res.data.filter((r) => r.gameName == "Survey")[0]);
      });
    });
  }, []);
  return (
    <div className={styles["main-div"]}>
      <div className={styles["heading-div"]}>
        <div className={styles["div-visitor"]}>
          <DrawerComponent />
          <div className={styles["hello"]}>Hello {name}</div>
        </div>
        <div className={styles["games-div"]}>
          <img src={games} />
          <span className={styles["games"]}>Featured Games</span>
        </div>
        <span className={styles["choose"]}>
          Choose a game to know more about VOIS
        </span>
      </div>

      <Slideshow />

      <div className={styles["heading-div"]}>
        <div className={styles["games-div"]}>
          <img src={surveyImg} />
          <span className={styles["games"]}>Visit Survey</span>
        </div>
        <span className={styles["choose"]}>
          Take the survey to help us improve future experiences
        </span>

        <div className={styles["survey-div"]}>
          <div className={styles["survey-back"]}>
            <img src={notes} />
          </div>

          <ButtonRounded
            value={survey?.isActive}
            text={"Take Survey"}
            color='black'
            borderColor='white'
            onClick={() => (survey?.isActive ? navigate("/survey") : null)}
          />
        </div>
      </div>
    </div>
  );
}

export default Landing;
