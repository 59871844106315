import AxiosInstance from "./config";

export const setGameActivation = (obj) => {
  return AxiosInstance.post("/roundGame/activate", obj);
};

export const getGamesByRoundID = (obj) => {
  return AxiosInstance.post("/roundGame/games-per-round-user", obj);
};

export const getGames = () => {
  return AxiosInstance.get("/roundGame/games");
};
export const postScore = (obj) => {
  return AxiosInstance.post("/gamescore", obj);
};
export const getgamesforRound = (obj) => {
  return AxiosInstance.post("/roundGame/games-per-round-user", obj);
};
