import React, { useEffect, useRef, useState } from "react";
import styles from "./SuperAdminDashBoardSurvey.module.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import axios from "axios";

import AdminAxiosInstance from "../../Services/AdminHTTPService";

const createChart = (containerId, data) => {
  const chart = am4core.create(containerId, am4charts.XYChart);
  chart.paddingRight = 20;

  chart.data = data;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "country";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.renderer.labels.template.horizontalCenter = "right";
  categoryAxis.renderer.labels.template.verticalCenter = "middle";
  categoryAxis.renderer.labels.template.rotation = 270;
  categoryAxis.tooltip.disabled = true;
  categoryAxis.renderer.minHeight = 110;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.minWidth = 50;
  chart.colors.list = [am4core.color("#e60000")];
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.sequencedInterpolation = true;
  series.dataFields.valueY = "visits";
  series.dataFields.categoryX = "country";
  series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
  series.columns.template.strokeWidth = 0;
  series.tooltip.pointerOrientation = "vertical";
  series.columns.template.column.cornerRadiusTopLeft = 10;
  series.columns.template.column.cornerRadiusTopRight = 10;
  series.columns.template.column.fillOpacity = 0.8;

  const hoverState = series.columns.template.column.states.create("hover");
  hoverState.properties.cornerRadiusTopLeft = 0;
  hoverState.properties.cornerRadiusTopRight = 0;
  hoverState.properties.fillOpacity = 1;

  series.columns.template.adapter.add("fill", (fill, target) => {
    return chart.colors.getIndex(target.dataItem.index);
  });

  chart.cursor = new am4charts.XYCursor();

  return chart;
};

function SuperAdminDashBoardSurvey({ roundID }) {
  const [finalQuestion, setFinalQuestion] = React.useState(false);
  const chartRef = useRef(null);
  const [survey, setSurvey] = useState([]);
  const [surveyLoading, setSurveyLoading] = useState(true);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [selectedQuestionstats, setSelectedQuestionstats] = useState({});

  useEffect(() => {
    AdminAxiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}surveys/getfinalsurvey`, {
        type: "final",
        round_id: roundID,
      })
      .then((response) => {
        setSurvey(response.data);
        setSurveyLoading(false);
        setSelectedQuestionIndex(0);
        setSelectedQuestion(response.data[0].question);
        setSelectedQuestionstats(response.data[0].counts);
      });
  }, [roundID]);

  useEffect(() => {
    if (!surveyLoading && chartRef.current) {
      // Dispose of the existing chart instance if it exists
      // if (chartRef.current.chart) {
      //   chartRef.current.chart.dispose();
      // }

      const data = Object.entries(selectedQuestionstats).map(
        ([key, value]) => ({
          country: key,
          visits: value,
        })
      );

      // Create and configure chart
      const chart = createChart("chartdiv", data);
      chartRef.current.chart = chart;
      setSurveyLoading(false);
    }
  }, [selectedQuestionIndex, surveyLoading, selectedQuestionstats]);

  const handleQuestionChange = (indexChange) => {
    const newIndex = selectedQuestionIndex + indexChange;
    if (newIndex >= 0 && newIndex < survey.length) {
      setSelectedQuestionIndex(newIndex);

      setSelectedQuestion(survey[newIndex].question);
      setSelectedQuestionstats(survey[newIndex].counts);
    }
  };
  const exportinitialsurvey = () => {
    AdminAxiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}surveys/getbyquestion`, {
        question: selectedQuestion,
        round_id: roundID,
      })
      .then((res) => {
        downloadSurvey(res.data);
      });
  };
  const downloadSurvey = (dataObj) => {
    var ws = XLSX.utils.json_to_sheet(
      dataObj.map((el) => {
        return {
          name: el.name,
          mobileNo:el.mobileNO,
          question: el.question,
          answer: el.answer,
          createdAt: new Date(el.createdAt).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            })
        };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "panel");

    /* generate an XLSX file */
    XLSX.writeFile(
      wb,
      "question_" + selectedQuestion + "_" + new Date() + ".xlsx"
    );
  };
  return (
    <>
      {surveyLoading ? (
        <div
          className="main-postKeynotes-page-body row"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="text-center">
            {[...Array(8)].map((_, idx) => (
              <div
                key={idx}
                className={`spinner-grow text-${
                  [
                    "primary",
                    "secondary",
                    "success",
                    "danger",
                    "warning",
                    "info",
                    "light",
                    "dark",
                  ][idx]
                }`}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles["expectations-div-body"] + " row"}>
          <div className={styles["btn-div"] + " col-md-12"}>
            <span
              className={styles["export-btn"]}
              onClick={() => {
                exportinitialsurvey();
              }}
            >
              Export Responses
            </span>
          </div>
          <div className={styles["game-item"] + " col-md-12"}>
            <div
              id="chartdiv"
              style={
                finalQuestion
                  ? { height: "100%", width: "50%", display: "none" }
                  : { height: "100%", width: "50%", display: "block" }
              }
              ref={(el) =>
                (chartRef.current = {
                  current: el,
                  chart: chartRef.current?.chart,
                })
              }
            ></div>

            <div className={styles["question-div"]}>
              <i
                className={
                  selectedQuestionIndex > 0
                    ? styles["enabled-arrow"] + " fa fa-arrow-left"
                    : styles["disabled-arrow"] + " fa fa-arrow-left"
                }
                onClick={() => {
                  if (selectedQuestionIndex == survey.length + 1) {
                    setFinalQuestion(false);
                    handleQuestionChange(-2);
                  } else {
                    handleQuestionChange(-1);
                  }
                }}
              ></i>
              <span className={styles["question"]}>{selectedQuestion}</span>

              <i
                className={
                  selectedQuestionIndex < survey.length
                    ? styles["enabled-arrow"] + " fa fa-arrow-right"
                    : styles["disabled-arrow"] + " fa fa-arrow-right"
                }
                onClick={() => {
                  if (selectedQuestionIndex == survey.length - 1) {
                    setFinalQuestion(true);
                    setSelectedQuestion(
                      "Was there anything specific that you liked / disliked or that we can do to make any future visit to our office more pleasant?"
                    );
                    setSelectedQuestionIndex(5);
                  } else handleQuestionChange(1);
                }}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuperAdminDashBoardSurvey;
