import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  paddingBottom: 3
};

export default function BasicModal({ text, img, heading, onClose }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {!onClose ? (
            <Grid
              display={"flex"}
              justifyContent="flex-end"
              sx={8}
              mx={2}
              my={1}
            >
              <IconButton onClick={() => setOpen(false)}>
                {" "}
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid py={2}></Grid>
          )}

          <Grid
            py={2}
            px={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            {img}
            <h2>{heading}</h2>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#262626",
              }}
            >
              {text}
            </Typography>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
