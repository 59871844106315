import "./index.css";
import React from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import { getCurrentUser } from "../../Services/authService";

import { CloseIcon } from "../../assets/icons/icons";
import { Modal } from "@mui/material";

import CustomSeparator from "../Breadcrumbs";
import axios from "axios";

import AdminAxiosInstance from "../../Services/AdminHTTPService";

export default function AdminViewHeader({ roundName, showSubHeader }) {
  const navigate = useNavigate();
  const [wantToClearDB, setWantToClearDB] = React.useState(false);

  const handleCloseClearDBModal = () => {
    setWantToClearDB(false);
  };

  const handleClearDB = () => {
    AdminAxiosInstance.post(`${process.env.REACT_APP_SERVER_URL}clearDB`, {});
  };

  return (
    <>
      <AppBar
        position='static'
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <div style={{ boxShadow: "0px 2px 8px 0px #00000029" }}>
          <Toolbar>
            <Typography
              variant='h5'
              style={{ fontWeight: 700, color: "#0D0D0D", paddingLeft: 80 }}
              sx={{ flexGrow: 1 }}
            >
              VOIS VISIT
            </Typography>
            <Box sx={{ flexGrow: 9, display: "flex" }}>
              <Button
                className='rounds-nav-btn'
                style={{
                  color: "#0D0D0D",
                  textTransform: "capitalize",
                  marginRight: 20,
                }}
                onClick={() => navigate("/admin/dashboard")}
              >
                Dashboard
              </Button>
              <Button
                className='rounds-nav-btn'
                style={{ color: "#0D0D0D", textTransform: "capitalize" }}
                onClick={() => navigate("/admin/home")}
              >
                Rounds
              </Button>
            </Box>
            <IconButton
              edge='end'
              color='inherit'
              onClick={(e) => {
                getCurrentUser().then((r) => {
                  AdminAxiosInstance
                    .post(`${process.env.REACT_APP_SERVER_URL}auth/logout`, {
                      email: r.preferred_username,
                    })
                    .then((x) => {
                      window.localStorage.clear();
                      window.sessionStorage.clear();
                      window.location.href = "/admin/login";
                    });
                });
              }}
              style={{ color: "#0D0D0D" }}
            >
              <img src='/assets/SignOut.webp' />
              <Typography
                variant='button'
                sx={{ marginLeft: 1, textTransform: "none" }}
              >
                Logout
              </Typography>
            </IconButton>

            <IconButton
              onClick={() => {
                setWantToClearDB(true);
              }}
              style={{ marginLeft: 20 }}
            >
              <img src='/assets/cleardb.webp' />
              <Typography
                variant='button'
                style={{
                  color: "black",
                  fontWeight: 500,
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                sx={{ marginLeft: 1, textTransform: "none" }}
              >
                Clear database
              </Typography>
            </IconButton>
          </Toolbar>
        </div>
        {showSubHeader && (
          <div
            style={{
              backgroundColor: "white",
              maxHeight: 40,
              marginTop: 8.2,
              boxShadow: "none",
              borderBottom: "0.1px solid  #BEBEBE",
            }}
          >
            <Toolbar>
              <CustomSeparator roundName={roundName} />
            </Toolbar>
          </div>
        )}
      </AppBar>
      <Modal open={wantToClearDB} onClose={handleCloseClearDBModal}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 480,
            backgroundColor: "white",
            borderRadius: 8,
            boxShadow: 24,
            outline: "none",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className='add-round-modal'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  color: "#E60000",
                  padding: "5px 5px 0px 5px",
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Clear database
              </h3>
              <CloseIcon onClick={handleCloseClearDBModal} />
            </div>

            <div
              style={{
                color: "#666666",
                fontWeight: 500,
                fontSize: 18,
                textAlign: "center",
                padding: 20,
              }}
            >
              Are you sure you want to the DB?
            </div>

            <button
              style={{
                backgroundColor: "#E60000",
                color: "white",
                borderColor: "transparent",
                alignSelf: "center",
                marginTop: 28,
                marginBottom: 30,
                marginRight: 25,
                padding: "10px 40px 10px 40px",
              }}
              onClick={() => {
                handleClearDB();
                handleCloseClearDBModal();
              }}
            >
              Clear
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
