import logo from "./logo.svg";
import "./App.css";
import Landing from "./Landing/Landing";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "./Services/authService";
import Entrance from "./Entrance";
import Thoughts from "./Thoughts";
import Welcome from "./Welcome";
import Survey from "./Survey";
import Scan from "./ScanQrCodes";
import TreasureHunt from "./TreasureHunt";
import RememberCard from "./RememberCard/RememberCard";
import SuperAdminLanding from "./SuperAdmin/SuperAdminLanding/SuperAdminLanding";
import SuperAdminRememberCards from "./SuperAdmin/SuperAdminRememberCards/SuperAdminRememberCards";
import AdminViewRoundsTable from "./components/AdminViewRoundsTable";
import AdminViewVisitorsTable from "./components/AdminViewVisitorsTable";
import Question from "./Questions";
import Results from "./Results";
import Profile from "./Profile";
import VirtualTourLanding from "./VirtualTour/VirtualTourLanding/VirtualTourLanding";
import VirtualTourHub2 from "./VirtualTour/VirtualTourHub2/VirtualTourHub2";
import { isMobile } from "react-device-detect";
import styles from "./App.module.css";
import OTP from "./OTP";
import SuperAdminDashBoard from "./SuperAdminDashboard/SuperAdminDashBoard";
import AdminLogin from "./AdminLogin/AdminLogin";
import { useIdleTimer } from "react-idle-timer";
import NoActiveRounds from "./NoActiveRounds";
import AdminEntrance from "./AdminEntrance/AdminEntrance";
import ProtectedAdminRoute from "./ProtectedAdminRoutes/ProtectedAdminRoute";
import axios from "axios";

import { fetchUserData } from "./AdminLogin/Auth/authProvider";
import VirtualTourDallah from "./VirtualTour/VirtualTourDallah/VirtualTourDallah";
function App() {
  const q = new URLSearchParams(window.location.search) || "";

  const [current, setCurrent] = React.useState();
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  let navigate = useNavigate();
  const onIdle = () => {
    getCurrentUser().then((r) => {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}auth/logout`, {
          email: r.preferred_username,
        })
        .then((x) => {
          sessionStorage.removeItem("token");
          if (window.location.pathname.includes("/admin")) {
            window.location.href =
              "https://login.microsoftonline.com/common/oauth2/logout";
          } else {
            navigate("/");
          }
        });
    });
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    throttle: 500,
    timeout: 7_200_000,
  });
  useEffect(() => {
    getCurrentUser().then((r) => {
      if (
        r == null &&
        window.location.pathname != "/otp" &&
        window.location.pathname != "/" &&
        window.location.pathname != "/admin/login" &&
        !window.location.pathname.includes("admin")
      ) {
        localStorage.setItem("Requested", window.location.pathname);
        localStorage.setItem("q", q);

        window.location.href = "/";
      }
    });
    if (
      window.location.pathname.includes("admin") &&
      window.location.pathname != "/admin/login"
    ) {
      const myfunction = async () => {
        await fetchUserData();
      };
      myfunction().then((r) => {
        getCurrentUser().then((r) => {
          setCurrent(r);
          if (
            r == null &&
            window.location.pathname != "/admin/login" &&
            window.location.pathname != "/" &&
            window.location.pathname != "/otp" &&
            window.location.pathname.includes("admin")
          ) {
            localStorage.setItem("Requested", window.location.pathname);
            localStorage.setItem("q", q);
            window.location.href = "/admin/login";
          }
        });
      });
    }
  }, []);

  return (
    <>
      <button
        type='button'
        class='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#concurrentuser'
        id='concurrentuserbtn'
        style={{ display: "none" }}
      >
        Concurrent user
      </button>
      <div
        className='modal fade'
        id='concurrentuser'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div
              className='modal-body row'
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>This User is logged in from another browser</p>
              <button
                className='btn btn-danger col-md-6'
                onClick={(e) => {
                  getCurrentUser().then((r) => {
                    axios
                      .post(`${process.env.REACT_APP_SERVER_URL}auth/logout`, {
                        email: r.preferred_username,
                      })
                      .then((x) => {
                        document.getElementById("concurrentuserbtn").click();
                        sessionStorage.removeItem("token");
                        if (window.location.pathname.includes("/admin")) {
                          window.location.href =
                            "https://login.microsoftonline.com/common/oauth2/logout";
                        } else {
                          navigate("/");
                        }
                      });
                  });
                }}
              >
                Log out{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        type='button'
        class='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#expiredtoken'
        id='expiredtokenbtn'
        style={{ display: "none" }}
      >
        expired token
      </button>
      <div
        className='modal fade'
        id='expiredtoken'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div
              className='modal-body row'
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>
                Your Session Has expired please logout to aquire a new token{" "}
              </p>
              <button
                className='btn btn-danger col-md-6'
                onClick={(e) => {
                  getCurrentUser().then((r) => {
                    axios
                      .post(`${process.env.REACT_APP_SERVER_URL}auth/logout`, {
                        email: r.preferred_username,
                      })
                      .then((x) => {
                        document.getElementById("expiredtokenbtn").click();
                        sessionStorage.removeItem("token");
                        if (window.location.pathname.includes("/admin")) {
                          window.location.href =
                            "https://login.microsoftonline.com/common/oauth2/logout";
                        } else {
                          navigate("/");
                        }
                      });
                  });
                }}
              >
                Log out{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type='button'
        class='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#wivamobile'
        id='wivamobilebtn'
        style={{ display: "none" }}
      >
        wivamobile
      </button>
      <div
        className='modal fade '
        id='wivamobile'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div
          className={styles["wiv-modal"] + " modal-dialog  "}
          role='document'
        >
          <div className='modal-content wiv-popup'>
            <div
              className='modal-body row'
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5% 10%",
              }}
            >
              <div className={styles["wiv-inner-div"] + " col-md-12"}>
                <div className='row'>
                  <div className={styles["wiv-mobile-tip"] + " col-md-6"}>
                    <img src='/assets/rotatemobile.webp' />
                    <span className={styles["wiv-tip-mobile-text-bolder"]}>
                      To ensure an optimal viewing experience, we suggest
                      switching to landscape mode. Kindly rotate your phone for
                      an amazing view.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path='/admin' element={<ProtectedAdminRoute />}>
          <Route path='dashboard' element={<SuperAdminDashBoard />} />
          <Route path='round' element={<AdminViewVisitorsTable />} />
          <Route path='home' element={<AdminViewRoundsTable />} />
          <Route index element={<Navigate to='/admin/home' replace />} />
        </Route>
        <Route path='/admin/login' element={<AdminLogin />} />
        ///////////////////////////////end of admin
        routes///////////////////////////////////
        <Route path='/' element={<ProtectedRoute></ProtectedRoute>}>
          <Route path='remembercard' element={<RememberCard />} />
          <Route path='home' element={<Landing />} />
          {/* <Route path='/superadmin/daghboard' element={<AdminViewTable/>} /> */}
          <Route path='thoughts' element={<Thoughts />} />
          <Route path='welcome' element={<Welcome />} />
          <Route path='scan' element={<Scan />} />
          <Route path='survey' element={<Survey />} />

          <Route path='treasure-hunt' element={<TreasureHunt />} />
          <Route path='questions/:id' element={<Question />} />
          <Route path='results' element={<Results />} />
          <Route path='profile' element={<Profile />} />
          <Route path='virtual-tour' element={<VirtualTourLanding />} />
          <Route path='hub2' element={<VirtualTourHub2 />} />
          <Route path='dallah' element={<VirtualTourDallah />} />
        </Route>
        <Route path='/otp' element={<OTP />} />
        <Route index path='/' element={<Entrance />} />
        <Route path='no-rounds' element={<NoActiveRounds />} />
        {/* WEBAR screens */}
        {/*element={<LoginFormNonVodafone} */}
      </Routes>
    </>
  );
}

export default App;
