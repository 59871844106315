import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { isMobile, isSafari } from "react-device-detect";
const ButtonRounded = ({ value, text, onClick, color, borderColor }) => {
  const takeSurveyStyleEnabler =
    text === "Take Survey"
      ? {
          backgroundColor: "transparent",
          border: "2px solid white",
          fontWeight: 400,
        }
      : {};

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    width: "152px",
    border: "1px solid",
    cursor: "pointer",
    borderRadius: "25px",
    lineHeight: 1.5,
    backgroundColor: color || "#E60000",
    borderColor: borderColor || "#E60000",

    "&:disabled": {
      color: "white",
      backgroundColor: "#F09A9A",
      borderColor: "#F09A9A",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: color || "#E60000",
      borderColor: borderColor || "#E60000",
    },
    "&:focus": {
      backgroundColor: color || "#E60000",
      borderColor: borderColor || "#E60000",
      boxShadow: "0 0 0 0.2rem rgba(230,12,20,.5)",
    },
    "&:hover": {
      backgroundColor: color || "#E60000",
      borderColor: borderColor || "#E60000",
      boxShadow: "0 0 0 0.2rem rgba(230,12,20,.5)",
    },
  });

  return (
    <BootstrapButton
      style={takeSurveyStyleEnabler}
      variant='contained'
      disabled={!value}
      onPointerEnter={isSafari ? onClick : null}
      onClick={onClick}
    >
      {text}
    </BootstrapButton>
  );
};
export default ButtonRounded;
