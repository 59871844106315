import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TH1 from "../assets/TH1.webp";
import TH2 from "../assets/TH2.webp";
import TH3 from "../assets/TH3.webp";

import { styled } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const NextBtn = styled(IconButton)({
  backgroundColor: "#E60000",
  color: "white",
  borderRadius: "28px",
  border: "1px solid #E60000",

  "&:active": {
    backgroundColor: "#E60000",
    color: "white",
    borderRadius: "28px",
    border: "1px solid #E60000",
  },
  "&:focus": {
    backgroundColor: "#E60000",
    color: "white",
    borderRadius: "28px",
    border: "1px solid #E60000",
  },
});

const TreasureHunt = () => {
  let navigate = useNavigate();
  const [steps, setSteps] = useState(0);
  const imgs = [TH1, TH2, TH3];
  const dot = (
    <div
      style={{
        display: "inlineBlock",
        height: "8px",
        width: "8px",
        borderRadius: "50%",
        cursor: "pointer",
        margin: "15px 3px 3px",
        backgroundColor: "#7E7E7E",
      }}
    ></div>
  );
  const text = [
    "Search the building for all the hidden QR codes",
    "Scan the QR codes and answer the questions",
    "Try to scan all the QR codes and answer the questions right for a chance to win some exciting prizes!",
  ];

  const activeDot = (
    <div
      style={{
        display: "inlineBlock",
        height: "8px",
        width: "8px",
        borderRadius: "50%",
        cursor: "pointer",
        margin: "15px 3px 3px",
        backgroundColor: "#E60000",
      }}
    ></div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>  
      <Grid display={"flex"} justifyContent='flex-end' sx={8} mx={2} my={2}>
        <IconButton
          sx={{ color: "black", cursor: "pointer" }}
          onPointerEnter={() => navigate("/home")}
          onClick={() => navigate("/home")}
        >
          {" "}
          <HighlightOffIcon fontSize='large' color='black' />
        </IconButton>
      </Grid>
      <Grid
        direction={"column"}
        container
        mt={8}
        rowGap={5}
        xs={12}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <img src={imgs[steps]} />
        <Grid
          direction={"row"}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          {imgs.map((_, idx) => {
            if (idx == steps) return activeDot;
            else return dot;
          })}
        </Grid>
        <Grid textAlign={"center"} my={2} xs={7}>
          <span>{text[steps]}</span>
        </Grid>
        <Grid
          container
          alignContent='center'
          px={2}
          mt={8}
          justifyContent='space-between'
        >
          <IconButton
            onClick={() => setSteps(steps - 1)}
            onPointerEnter={() => setSteps(steps - 1)}
            sx={{
              color: "black",
              cursor: "pointer",
              borderRadius: "28px",
              border: "1px solid black",
              visibility: steps == 0 ? "hidden" : "visible",
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          {steps == 2 ? (
            <ButtonRounded
              value={true}
              text={"Start"}
              onClick={() => navigate("/scan")}
            />
          ) : (
            <NextBtn
              onClick={() => setSteps(steps + 1)}
              onPointerEnter={() => setSteps(steps + 1)}
              sx={{
                visibility: steps == 2 ? "hidden" : "visible",
                cursor: "pointer",
              }}
            >
              <NavigateNextIcon />
            </NextBtn>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TreasureHunt;
