import React from "react";
import welcome from "../assets/welcome.webp";
import Heading from "../assets/Heading.webp";
import Text from "../assets/welcome-text.webp";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  let navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }} mt={30} alignItems='center'>
      <Grid
        direction={"column"}
        container
        margin={0}
        rowGap={5}
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img src={welcome} />
        <Grid
          my={5}
          container
          rowGap={3}
          direction={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <img src={Heading} />
          <img src={Text} />
        </Grid>

        <ButtonRounded
          value={true}
          text={"start"}
          onClick={() => navigate("/home")}
        />
      </Grid>
    </Box>
  );
};

export default Welcome;
