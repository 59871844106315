import Axios from "axios";
import CryptoJS from "crypto-js";
import { generateTokenFingerprint } from "../Services/httpService";

// Create Axios instance
const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
  },
});

// Interceptor to handle request encryption and token/fingerprint
AxiosInstance.interceptors.request.use(async (request) => {
  try {
    const encryptedToken = sessionStorage.getItem("token");

    // Generate token fingerprint asynchronously
    const fingerprint = await generateTokenFingerprint();

    // Set headers
    request.headers.Authorization = `Bearer ${encryptedToken}`;
    request.headers["X-Token-FingerPrint"] = fingerprint;

    // Encrypt request data if available
    if (request.data) {
      request.data = {
        payload: CryptoJS.AES.encrypt(
          JSON.stringify(request.data),
          "secret key 123"
        ).toString(),
      };
    }

    return request;
  } catch (error) {
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
});

// Response decryption interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    // Only decrypt responses that don't belong to certain APIs
    if (
      response.config.url.includes("/api/mosaicWall") ||
      response.config.url.includes("/api/eventassets") ||
      response.config.url.includes("/api/stats/setpage")
    ) {
      return response; // Return as is for these endpoints
    }

    // Decrypt the response data
    try {
      let decrypted = CryptoJS.AES.decrypt(
        response.data,
        "secret key 123"
      ).toString(CryptoJS.enc.Utf8);

      let decryptedItem = decrypted ? JSON.parse(decrypted) : "";
      response.data = decryptedItem;

      return response;
    } catch (error) {
      console.error("Error decrypting response:", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    console.error("Response error:", error);

    // Handle encrypted error messages
    if (error.response && error.response.data) {
      let decrypted = CryptoJS.AES.decrypt(
        error.response.data,
        "secret key 123"
      ).toString(CryptoJS.enc.Utf8);
      let decryptedItem = decrypted ? JSON.parse(decrypted) : "";

      error.response.data = decryptedItem;

      // Handle specific decrypted error messages
      if (decryptedItem === "Concurrent User") {
        document.getElementById("concurrentuserbtn").click();
      } else if (decryptedItem === "Expired Token") {
        document.getElementById("expiredtokenbtn").click();
      }
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
