import React, { useState, useEffect } from "react";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import profile from "../assets/profile.webp";
import { getProfile } from "../apis/Users";
import { getCurrentUser } from "../Services/authService";

const Profile = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  useEffect(() => {
    getCurrentUser().then((token) => {
      getProfile({ mobileNO: token.mobileNO }).then((res) => {
        setName(res.data.name);
        setMobileNo(res.data.mobileNO);
      });
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} my={1} alignItems='center'>
      <Grid mx={2} my={4}>
        <IconButton
          onClick={() => navigate(-1)}
          onPointerEnter={() => navigate(-1)}
          sx={{
            border: "1px solid black",
            color: "black",
            borderRadius: "25px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid
        alignItems={"center"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <img src={profile} />

        <Card
          sx={{
            width: "80%",
            display: "flex",
            height: "110px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 2px 8px 0px #00000029",
            paddingBottom: "20px",
          }}
        >
          <h4 style={{ textTransform: "capitalize" }}>{name}</h4>
          <span>Phone number: {mobileNo}</span>
        </Card>
      </Grid>
    </Box>
  );
};

export default Profile;
