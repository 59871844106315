import React, { useEffect, useState } from "react";
import ThoughtsImg from "../assets/thoughts.webp";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";
import { saveSurvey } from "../apis/Survey";
import { getCurent } from "../apis/Round";
import sent from "../assets/sent.webp";
import BasicModal from "../components/ModalMessage";
import { getAnswerByMobile } from "../apis/Survey";
import { type } from "@testing-library/user-event/dist/type";
import { getIdToken } from "../AdminLogin/Auth/authProvider";
import { getCurrentUser } from "../Services/authService";

const Thoughts = () => {
  let navigate = useNavigate();
  const [thoughts, setThoughts] = useState("");

  useEffect(() => {
    getCurrentUser().then((r) => {
      if (r) {
        getAnswerByMobile({
          mobileNO: r.mobileNO,
          type: "expectation",
        })
          .then((res) => {

            if (res.data.length > 0) {
              navigate("/home");
            } else {
              navigate("/thoughts");
            }
          })
          .catch((err) => {
            console.error("Error fetching profile:", err);
            navigate("/thoughts");
          });
      } else {
        navigate("/thoughts");
      }
    });
  }, [navigate]);
  const [openMsg, setOpenMSg] = useState(false);
  const handleSave = () => {
    getCurrentUser().then((token) => {
      getCurent().then((r) => {
        saveSurvey({
          type: "expectation",
          name: token.name,
          mobileNO: token.mobileNO,
          round_id: r.data._id,
          question: "Please enter your thoughs here",
          answer: thoughts,
        }).then((res) => {
          if (res.status == 200) setOpenMSg(true);
        });
      });
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }} my={4} alignItems='center'>
      {openMsg && (
        <BasicModal
          onClose={() => navigate("/welcome")}
          img={<img width={"47px"} height={"47px"} src={sent} />}
          text='Thank you, Your response has been successfully submitted.'
        />
      )}
      <Grid
        direction={"column"}
        container
        margin={0}
        rowGap={5}
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img src={ThoughtsImg} />

        <FormControl sx={{ m: 1, width: "30ch" }} variant='outlined'>
          <OutlinedInput
            value={thoughts}
            multiline
            rows={4}
            onChange={(e) => setThoughts(e.target.value)}
            placeholder='Please enter your thoughts here'
            id='Thoughts'
            aria-describedby='outlined-Thoughts-helper-text'
            inputProps={{
              "aria-label": "Thoughts",
            }}
          />
        </FormControl>

        <ButtonRounded
          value={thoughts}
          text={"Next"}
          onClick={() => handleSave()}
        />
      </Grid>
    </Box>
  );
};

export default Thoughts;
