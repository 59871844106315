import * as React from "react";
import "./index.css";
import { AddIcon, CloseIcon, SearchIcon } from "../../assets/icons/icons";
import { useState, useCallback } from "react";
import { Modal, Box } from "@mui/material";

import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import * as Yup from "yup";

import AdminAxiosInstance from "../../Services/AdminHTTPService";

const BasicDatePicker = ({
  selectedDate,
  handleDateChange,
  minDate,
  maxDate,
  isStartDate,
}) => {
  const minEndDate = dayjs(minDate).add(1, "day");
  const maxStartDate = dayjs(maxDate);
  //const futureDisabled = isStartDate === "roundEndDate" ? true : false;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: "94%", borderRadius: 5 }}
        value={selectedDate}
        onChange={handleDateChange}
        label=''
        disablePast={true}
        minDate={minDate ? minEndDate : null}
        maxDate={maxDate ? maxStartDate : null}
        name={isStartDate}
      />
    </LocalizationProvider>
  );
};

const RoundDates = ({
  date,
  selectedDate,
  handleDateChange,
  minDate,
  maxDate,
}) => {
  const isStartDate =
    date === "Round Start Date" ? "roundStartDate" : "roundEndDate";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 25,
        paddingTop: 20,
        paddingBottom: 5,
      }}
    >
      <label style={{ color: "#666666", fontWeight: 700, paddingBottom: 20 }}>
        {date}
      </label>
      <BasicDatePicker
        minDate={minDate}
        maxDate={maxDate}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        isStartDate={isStartDate}
      />
    </div>
  );
};

export default function AdminViewTitle({
  showSearchField,
  buttonFunctionality,
  tableContent,
  titleIcon,
}) {
  const [showAddRoundModal, setShowAddRoundModal] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [roundName, setRoundName] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [nameVErrors, setNameVErrors] = useState();
  const [startDateVErrors, setStartDateVErrors] = useState();
  const [endDateVErrors, setEndDateVErrors] = useState();

  const roundSchema = Yup.object().shape({
    roundName: Yup.string().min(5).required("Required"),
    roundStartDate: Yup.date().required("Required"),
    roundEndDate: Yup.date().required("Required"),
  });

  const handleAddRoundSubmit = (e) => {
    e.preventDefault();

    const formData = {
      roundName: e.target.roundName.value,
      roundStartDate: e.target.roundStartDate.value,
      roundEndDate: e.target.roundEndDate.value,
    };

    const errors = roundSchema.validate(formData, { abortEarly: false });

    errors
      .then(() => {
        handleAddRound();
        setRoundName(null);
        setSelectedEndDate(null);
        setSelectedStartDate(null);
        handleCloseModal();
      })
      .catch((es) => {
        es.inner.map((vError, i) => {
          if (vError.path === "roundName") setNameVErrors(vError.message);
          else if (vError.path === "roundStartDate")
            setStartDateVErrors("Required");
          else if (vError.path === "roundEndDate")
            setEndDateVErrors("Required");
        });
      });
  };

  const updateValue = (event) => {
    setSearchParams({ name: event.target.value });
  };

  const debouncedChangeHandler = useCallback(debounce(updateValue, 500), []);

  const handleRoundNameChange = (event) => {
    setRoundName(event.target.value);
    setNameVErrors(null);
  };

  const handleStartDateChange = (newValue) => {
    setSelectedStartDate(newValue);
    setStartDateVErrors(null);
  };

  const handleEndDateChange = (newValue) => {
    setSelectedEndDate(newValue);
    setEndDateVErrors(null);
  };

  const handleCloseModal = () => {
    setShowAddRoundModal(false);
  };

  const handleAddRound = async () => {
    await AdminAxiosInstance.post(`${process.env.REACT_APP_SERVER_URL}round/add`, {
      name: roundName,
      startDate: dayjs(selectedStartDate)
        .endOf("day")
        .set("hour", 3)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0),
      endDate: dayjs(selectedEndDate)
        .endOf("day")
        .set("hour", 3)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0),
      status: "Deactivated",
    });

    document.getElementById("add-round").click();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: 50,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {titleIcon}
          <label style={{ fontWeight: 700, fontSize: 24, paddingLeft: 5 }}>
            {tableContent}
          </label>
        </div>
        {showSearchField && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className='search-field'
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginRight: 20,
                alignItems: "center",
              }}
            >
              <input
                className='search-input'
                style={{
                  borderWidth: 1,
                  borderRadius: "6px 0 0 6px",
                  minWidth: 300,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 8.5,
                  paddingBottom: 8.5,
                  border: ".2px solid #7E7E7E",
                  alignSelf: "center",
                }}
                placeholder='Search'
                onChange={debouncedChangeHandler}
              />
              <div
                style={{
                  borderRadius: "0 6px 6px 0",
                  width: 42,
                  height: 42,
                  backgroundColor: "#E60000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchIcon />
              </div>
            </div>
            {buttonFunctionality === "Add Round" && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E60000",
                  color: "white",
                  borderColor: "transparent",
                }}
                onClick={() => setShowAddRoundModal(true)}
              >
                {buttonFunctionality} {<AddIcon />}
              </button>
            )}
            {buttonFunctionality === "Import data" && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E60000",
                  color: "white",
                  borderColor: "transparent",
                }}
                onClick={() => {
                  document.getElementById("file-2nd-upload").click();
                }}
              >
                {buttonFunctionality} {<AddIcon />}
              </button>
            )}
          </div>
        )}
      </div>

      <Modal open={showAddRoundModal} onClose={handleCloseModal}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 480,
            backgroundColor: "white",
            borderRadius: 8,
            boxShadow: 24,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className='add-round-modal'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  color: "#E60000",
                  padding: "10px 5px 0px 5px",
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Round details
              </h3>
              <CloseIcon onClick={handleCloseModal} />
            </div>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={handleAddRoundSubmit}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 25,
                  paddingTop: 20,
                }}
              >
                <label
                  style={{
                    marginBottom: 15,
                    color: "#666666",
                    fontWeight: 700,
                  }}
                >
                  Round Name
                </label>
                <input
                  className='modal-input'
                  style={{ width: "93%", padding: 15, paddingRight: "-10px" }}
                  value={roundName}
                  onChange={handleRoundNameChange}
                  type='text'
                  name='roundName'
                />
                <p className='error-msg'>{nameVErrors}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <RoundDates
                  selectedDate={selectedStartDate}
                  handleDateChange={handleStartDateChange}
                  date={"Round Start Date"}
                  minDate={null}
                  maxDate={dayjs(selectedEndDate)}
                />
                <p className='error-msg' style={{ paddingLeft: 26 }}>
                  {startDateVErrors}
                </p>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <RoundDates
                  selectedDate={selectedEndDate}
                  handleDateChange={handleEndDateChange}
                  date={"Round End Date"}
                  minDate={dayjs(selectedStartDate)}
                  maxDate={null}
                />
                <p className='error-msg' style={{ paddingLeft: 26 }}>
                  {endDateVErrors}
                </p>
              </div>

              <button
                type='submit'
                style={{
                  backgroundColor: "#E60000",
                  color: "white",
                  borderColor: "transparent",
                  alignSelf: "flex-end",
                  marginTop: 20,
                  marginBottom: 30,
                  marginRight: 25,
                  padding: "15px 40px 15px 40px",
                }}
              >
                Add Round
              </button>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
