import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";

import { AddIcon, Papers, RoundSVGIcon } from "../../assets/icons/icons";
import AdminViewHeader from "../AdminViewHeader";
import AdminViewTitle from "../AdminViewTitle";

import AdminAxiosInstance from "../../Services/AdminHTTPService";

import "./index.css";

// ... JSX ... //
const IsActivated = ({ isActive }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <label
        style={{
          backgroundColor: isActive === "Activated" ? "#008A00" : "#BEBEBE",
          color: isActive === "Activated" ? "white" : "black",
          padding: "4px 16px 4px 16px",
          textAlign: "center",
          width: "40%",
          borderRadius: 13,
        }}
      >
        {isActive === "Activated" ? "Activated" : "Deactivated"}
      </label>
    </div>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// ... Helpers & Vars ... //

const columns = [
  {
    name: "Visitor Name",
    icon: (
      <svg
        width='19'
        height='12'
        viewBox='0 0 20 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.25 0.9375H1.75C1.4019 0.9375 1.06806 1.07578 0.821922 1.32192C0.575781 1.56806 0.4375 1.9019 0.4375 2.25V15.75C0.4375 16.0981 0.575781 16.4319 0.821922 16.6781C1.06806 16.9242 1.4019 17.0625 1.75 17.0625H18.25C18.5981 17.0625 18.9319 16.9242 19.1781 16.6781C19.4242 16.4319 19.5625 16.0981 19.5625 15.75V2.25C19.5625 1.9019 19.4242 1.56806 19.1781 1.32192C18.9319 1.07578 18.5981 0.9375 18.25 0.9375ZM18.4375 15.75C18.4375 15.7997 18.4177 15.8474 18.3826 15.8826C18.3474 15.9177 18.2997 15.9375 18.25 15.9375H1.75C1.70027 15.9375 1.65258 15.9177 1.61742 15.8826C1.58225 15.8474 1.5625 15.7997 1.5625 15.75V2.25C1.5625 2.20027 1.58225 2.15258 1.61742 2.11742C1.65258 2.08225 1.70027 2.0625 1.75 2.0625H18.25C18.2997 2.0625 18.3474 2.08225 18.3826 2.11742C18.4177 2.15258 18.4375 2.20027 18.4375 2.25V15.75ZM15.0625 6C15.0625 6.14918 15.0032 6.29226 14.8977 6.39775C14.7923 6.50324 14.6492 6.5625 14.5 6.5625H5.5C5.35082 6.5625 5.20774 6.50324 5.10225 6.39775C4.99676 6.29226 4.9375 6.14918 4.9375 6C4.9375 5.85082 4.99676 5.70774 5.10225 5.60225C5.20774 5.49676 5.35082 5.4375 5.5 5.4375H14.5C14.6492 5.4375 14.7923 5.49676 14.8977 5.60225C15.0032 5.70774 15.0625 5.85082 15.0625 6ZM15.0625 9C15.0625 9.14918 15.0032 9.29226 14.8977 9.39775C14.7923 9.50324 14.6492 9.5625 14.5 9.5625H5.5C5.35082 9.5625 5.20774 9.50324 5.10225 9.39775C4.99676 9.29226 4.9375 9.14918 4.9375 9C4.9375 8.85082 4.99676 8.70774 5.10225 8.60225C5.20774 8.49676 5.35082 8.4375 5.5 8.4375H14.5C14.6492 8.4375 14.7923 8.49676 14.8977 8.60225C15.0032 8.70774 15.0625 8.85082 15.0625 9ZM15.0625 12C15.0625 12.1492 15.0032 12.2923 14.8977 12.3977C14.7923 12.5032 14.6492 12.5625 14.5 12.5625H5.5C5.35082 12.5625 5.20774 12.5032 5.10225 12.3977C4.99676 12.2923 4.9375 12.1492 4.9375 12C4.9375 11.8508 4.99676 11.7077 5.10225 11.6023C5.20774 11.4968 5.35082 11.4375 5.5 11.4375H14.5C14.6492 11.4375 14.7923 11.4968 14.8977 11.6023C15.0032 11.7077 15.0625 11.8508 15.0625 12Z'
          fill='#4A4D4E'
        />
      </svg>
    ),
  },
  {
    name: "Phone Number",
    icon: (
      <svg
        width='20'
        height='17'
        viewBox='0 0 20 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.5 1.6875H7.5C6.95299 1.6875 6.42839 1.9048 6.04159 2.29159C5.6548 2.67839 5.4375 3.20299 5.4375 3.75V20.25C5.4375 20.797 5.6548 21.3216 6.04159 21.7084C6.42839 22.0952 6.95299 22.3125 7.5 22.3125H16.5C17.047 22.3125 17.5716 22.0952 17.9584 21.7084C18.3452 21.3216 18.5625 20.797 18.5625 20.25V3.75C18.5625 3.20299 18.3452 2.67839 17.9584 2.29159C17.5716 1.9048 17.047 1.6875 16.5 1.6875ZM6.5625 5.8125H17.4375V18.1875H6.5625V5.8125ZM7.5 2.8125H16.5C16.7486 2.8125 16.9871 2.91127 17.1629 3.08709C17.3387 3.2629 17.4375 3.50136 17.4375 3.75V4.6875H6.5625V3.75C6.5625 3.50136 6.66127 3.2629 6.83709 3.08709C7.0129 2.91127 7.25136 2.8125 7.5 2.8125ZM16.5 21.1875H7.5C7.25136 21.1875 7.0129 21.0887 6.83709 20.9129C6.66127 20.7371 6.5625 20.4986 6.5625 20.25V19.3125H17.4375V20.25C17.4375 20.4986 17.3387 20.7371 17.1629 20.9129C16.9871 21.0887 16.7486 21.1875 16.5 21.1875Z'
          fill='#4A4D4E'
        />
      </svg>
    ),
  },
  { name: "Status" },
];

export default function AdminViewVisitorsTable() {
  const location = useLocation();
  const [isDataImported, setIsDataImported] = React.useState(false);
  const [doesIthaveData, setDoesIthaveData] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const [searchParams, setSearchParams] = useSearchParams();

  const { round_id } = location.state || "";
  const [roundName, setRoundName] = React.useState("");

  const [parsedXLSXData, setParsedXLSXData] = React.useState(null);
  const [importAgain, setImportAgain] = React.useState(false);
  const [visitorsFiltered, setVisitorsFiltered] =
    React.useState(parsedXLSXData);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [duplicateVisitors, setDuplicateVisitors] = React.useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setErrorMessages([]);
    setIsDataImported(false);
    setImportAgain(false);

    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: "array",
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const columns = Object.keys(jsonData[0]);

      validateData(jsonData, columns);
    };

    reader.readAsArrayBuffer(file);

    event.target.value = null;
  };
  const validateData = (data, columns) => {
    const expectedColumns = ["visitor_name", "phone_number"];
    const optionalColumns = ["status"];
    const allColumns = [...expectedColumns, ...optionalColumns];
    const errors = [];

    // Validate column names
    columns.forEach((columnName, index) => {
      if (!allColumns.includes(columnName)) {
        errors.push(
          `Error: Incorrect column name '${columnName}' at position ${
            index + 1
          }. Expected ${columnName}.`
        );
      }
    });

    optionalColumns.forEach((optionalColumn) => {
      const optionalColumnIndex = columns.indexOf(optionalColumn);
      if (optionalColumnIndex !== -1) {
        data.forEach((row, index) => {
          const status = row[optionalColumn];
          if (
            status &&
            !["Active", "Inactive", "Deactivated", "Activated"].includes(status)
          ) {
            // Updated to include 'Activated'
            errors.push(
              `Row B${
                index + 1
              }: Invalid status value '${status}'. Must be one of 'Active', 'Inactive', 'Deactivated', 'Activated'.`
            );
          }
        });
      }
    });

    // Validate phone numbers
    data.forEach((row, index) => {
      const phoneNumber = row["phone_number"];
      if (!/^\d{10}$/.test(phoneNumber)) {
        errors.push(
          `Row B${
            index + 1
          }: Invalid phone number format. Must be 10 digits without spaces.`
        );
      }
    });

    if (errors.length > 0) {
      setErrorMessages(errors);
      setIsDataImported(false);
    } else {
      setImportAgain(true);
      setErrorMessages([]);
      setParsedXLSXData(data);
      setIsDataImported(true);
    }
  };

  const deleteBatchOfMembers = async () => {
    await AdminAxiosInstance.post(`${process.env.REACT_APP_SERVER_URL}member/delete`, {
      roundID: round_id,
    });
  };
  const handleExportData = () => {
    // Define the sample data
    const expectedColumns = ["visitor_name", "phone_number", "status"];

    const sampleData = [
      {
        visitor_name: "John Doe",
        phone_number: "1234567890",
        status: "Activated",
      },
      {
        visitor_name: "Jane Smith",
        phone_number: "0987654321",
        status: "Deactivated",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sample.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addBatchOfMembers = async () => {
    await AdminAxiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}member/bulkadd`, {
        arr: parsedXLSXData.map((r) => {
          return {
            name: r["visitor_name"],
            mobileNO: r["phone_number"],
            roundID: round_id,
            status: "Deactivated",
          };
        }),
        roundID: round_id,
      })
      .then((res) => {
        setDuplicateVisitors([]);
      })
      .catch((res) => {
        const data = JSON.parse(res.response.data);
        //if(parsedXLSXData.length === data.length)
        setDuplicateVisitors(() => [...data]);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRound = async (id) => {
    await AdminAxiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}round/round`, { _id: id })
      .then((res) => {
        setRoundName(res.data[0].name);
      });
  };

  const getVisitors = async (round_id) => {
    await AdminAxiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}member/all`, {
        roundID: round_id,
      })
      .then((res) => {
        setParsedXLSXData(res.data);
        if (res.data.length !== 0) setDoesIthaveData(true);
      });
  };

  React.useEffect(() => {
    getRound(round_id);
    getVisitors(round_id);
  }, []);

  React.useEffect(() => {
    const filteredVisitors = parsedXLSXData?.filter((visitor) =>
      (visitor["visitor_name"] || visitor["name"])
        ?.toLowerCase()
        .includes(searchParams.get("name")?.toLowerCase())
    );

    setVisitorsFiltered(filteredVisitors);
  }, [searchParams, parsedXLSXData]);

  React.useEffect(() => {
    if (isDataImported) addBatchOfMembers();
  }, [parsedXLSXData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F2F2F2",
        height: "100vh",
      }}
    >
      <AdminViewHeader roundName={roundName} showSubHeader={true} />
      {duplicateVisitors?.length > 0 &&
        duplicateVisitors?.length < parsedXLSXData?.length && (
          <Alert severity='error'>
            {duplicateVisitors.map((phoneNo, idx) => (
              <div>
                Visitor with {phoneNo} already registered on different round.
              </div>
            ))}
          </Alert>
        )}

      {duplicateVisitors?.length === parsedXLSXData?.length &&
        isDataImported && (
          <Alert severity='error'>
            <div>
              You've probably uploaded this sheet before for different round,
              please try again!
            </div>
          </Alert>
        )}
      {errorMessages.length > 0 && (
        <Alert severity='error'>
          {errorMessages.map((message, idx) => (
            <div key={idx}>{message}</div>
          ))}
        </Alert>
      )}
      <AdminViewTitle
        showSearchField={isDataImported || doesIthaveData}
        titleIcon={<RoundSVGIcon />}
        tableContent={roundName}
        buttonFunctionality={"Import data"}
      />

      <TableContainer
        sx={{ overflowY: "scroll" }}
        className='table-container hide-scrollbar'
        style={{
          width: "90%",
          minHeight: isDataImported || doesIthaveData ? "auto" : 400,
        }}
        component={Paper}
      >
        <Table stickyHeader sx={{ minWidth: 500 }} aria-label='simple table'>
          {isDataImported || doesIthaveData ? (
            <>
              <TableHead>
                <TableRow>
                  {columns.map((col, indx) => {
                    const align = indx !== 2 ? "left" : "center";
                    return (
                      <TableCell
                        key={indx}
                        style={{
                          fontWeight: 700,
                          fontSize: 15,
                          color: "#E60000",
                          paddingLeft: indx === 0 ? 50 : "auto",
                        }}
                        align={align}
                      >
                        {!(indx === columns.length - 1) && (
                          <label style={{ paddingRight: 7 }}>{col.icon}</label>
                        )}
                        <label>{col.name}</label>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? (searchParams.get("name")
                      ? visitorsFiltered
                      : parsedXLSXData.filter(
                          (item) =>
                            !duplicateVisitors.includes(
                              item["phone_number"] ||
                                !duplicateVisitors.includes(item["mobileNO"])
                            )
                        )
                    )?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : searchParams.get("name")
                  ? visitorsFiltered
                  : parsedXLSXData.filter(
                      (item) =>
                        !duplicateVisitors.includes(
                          item["phone_number"] ||
                            !duplicateVisitors.includes(item["mobileNO"])
                        )
                    )
                )?.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className='round-name-col'
                      style={{
                        paddingLeft: 70,
                        maxWidth: 300,
                        textTransform: "capitalize",
                      }}
                    >
                      {row["visitor_name"] || row["name"]}
                    </TableCell>
                    <TableCell align='left'>
                      {row["phone_number"] || row["mobileNO"]}
                    </TableCell>
                    <TableCell align='right'>
                      <IsActivated isActive={row["status"]} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      { label: "4  Rows", value: 4 },
                      { label: "10  Rows", value: 10 },
                      { label: "All", value: -1 },
                    ]}
                    colSpan={columns.length}
                    count={
                      (searchParams.get("name")
                        ? visitorsFiltered
                        : parsedXLSXData
                      )?.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
              <input
                style={{ display: "none" }}
                type='file'
                accept='.xlsx'
                id='file-2nd-upload'
                onClick={() => setImportAgain(true)}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  {columns.map((col, indx) => {
                    const align = indx === 1 ? "center" : "";
                    return (
                      <TableCell
                        key={indx}
                        style={{
                          fontWeight: 700,
                          fontSize: 15,
                          color: "#E60000",
                          paddingRight: indx === 1 ? 120 : "auto",
                        }}
                        align={align}
                      >
                        {!(indx === columns.length - 1) && (
                          <label style={{ paddingRight: 5 }}>{col.icon}</label>
                        )}
                        <label>{col.name}</label>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell> {/* Empty first column */}
                  <TableCell align='left' sx={{ height: "350px" }}>
                    <div
                      style={{
                        width: "72%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Papers />
                      <label
                        style={{
                          color: "#262626",
                          textAlign: "center",
                          fontWeight: 400,
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        No data imported yet
                      </label>
                      <label
                        style={{
                          color: "#4A4D4E",
                          textAlign: "center",
                          fontWeight: 200,
                          marginBottom: 20,
                          width: "60%",
                        }}
                      >
                        Download the template to fill the visitors data and then
                        import the data here
                      </label>
                      <input
                        style={{ display: "none" }}
                        type='file'
                        accept='.xlsx'
                        id='file-upload'
                        onChange={handleFileChange}
                      />

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            width: "40%",
                            textAlign: "center",
                            padding: 1,
                            backgroundColor: "white",
                            color: "white",
                            borderColor: "black",
                            color: "black",
                            "&:hover": { cursor: "pointer" },
                          }}
                          onClick={handleExportData}
                        >
                          Download Template{" "}
                          <FileDownloadOutlinedIcon
                            style={{ paddingLeft: 10, fontSize: "30" }}
                          />
                        </button>
                        <button
                          type='file'
                          style={{
                            width: "40%",
                            textAlign: "center",
                            backgroundColor: "#E60000",
                            color: "white",
                            borderColor: "transparent",
                            "&:hover": { cursor: "pointer" },
                          }}
                          onClick={() => {
                            document.getElementById("file-upload").click();
                          }}
                        >
                          Import data {<AddIcon />}
                        </button>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
