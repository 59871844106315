import React, { useEffect, useState } from "react";
import DrawerComponent from "../components/Drawer";
import { QrScannerRender } from "../components/QR-scanner";
import QRcode from "../assets/QR-Code.webp";
import styles from "./Scan.module.css";
import ButtonRounded from "../components/ButtonRounded";
import { useNavigate } from "react-router-dom";
import {
  startGame,
  checkIfQRScanned,
  calculateResults,
  getRemaining,
  updateQRs,
} from "../apis/ScannedQRs";
import { getCurent } from "../apis/Round";
import { getProfile } from "../apis/Users";
import { getgamesforRound, postScore } from "../apis/RoundGame";
import BasicModal from "../components/ModalMessage";
import scanMsg from "../assets/scan-msg.webp";
import { getCurrentUser } from "../Services/authService";

const Scan = () => {
  const [openQR, setOpenQr] = useState(false);
  const [msg, setMsg] = useState("");
  const [score, setScore] = useState(0);
  const [openMsg, setOpenMSg] = useState(false);
  let navigate = useNavigate();
  const [remaining, setRemaining] = useState(null);

  const submitScore = async (fScore, Duration, scanned) => {
    try {
      getCurent().then(async (r) => {
        // First API call to get the game data and ID
        const gameResponse = await getgamesforRound({
          roundID: r.data._id,
        });
        const specificGame = gameResponse.data.find(
          (game) => game.gameName === "Treasure hunt"
        ); // Replace with your condition
        if (!specificGame) {
          alert("Error: Game not found.");
          return;
        }

        const gameId = specificGame._id;
        getCurrentUser().then((token) => {
          getProfile({ mobileNO: token.mobileNO }).then(async (x) => {
            const scoreData = {
              user_id: x.data._id,
              game_id: gameId,
              round_id: r.data._id,
              score: fScore,
              time_spent: Duration,
              is_completed: scanned == 7 ? true : false,
            };

            // Check if all required data is present

            // Second API call to submit the score
            const response = await postScore(scoreData).then((res) => {
            });
          });
        });
      });
    } catch (error) {
      console.error("Error saving score:", error);
    }
  };

  useEffect(() => {
    getCurrentUser().then((token) => {
      getRemaining({ mobileNO: token.mobileNO }).then((res) => {
        if (res.data == 7) {
          startGame({ mobileNO: token.mobileNO }).then((res) => {});
        } else {
          calculateResults({ mobileNO: token.mobileNO }).then((res) => {
            setScore(res.data.finalScore);
          });
        }
        setRemaining(res.data);
      });

      getgamesforRound({ roundID: token.roundID }).then((res) => {
        const TH = res.data.find((game) => game.gameName === "Treasure hunt");

        if (TH?.isActive == false || remaining == 0) {
          calculateResults({ mobileNO: token.mobileNO }).then((res) => {
            setScore(res.data.finalScore);

            submitScore(
              res.data.finalScore,
              res.data.duration,
              res.data.numberOfScanned
            );
          });
          navigate("/results");
        }
      });
    });
  });
  const handleAfterScannedURL = (message) => {
    setOpenMSg(false);
    let qr = message?.split(".com");
    setOpenQr(false);
    getCurrentUser().then((token) => {
      checkIfQRScanned({ mobileNO: token.mobileNO, QR: qr[1] }).then((res) => {
        if (res.data == false) {
          updateQRs({ mobileNO: token.mobileNO, QR: qr[1] }).then((res) => {

            navigate(qr[1]);
          });
        } else {
          setOpenMSg(true);
        }

        setMsg("");
      });
    });
  };
  if (openQR)
    return (
      <div className={styles["main-div"]}>
        <QrScannerRender
          setOpenQr={setOpenQr}
          setMsg={setMsg}
          handleAfterScannedURL={handleAfterScannedURL}
        />
      </div>
    );
  return (
    <div className={styles["main-div"]}>
      {openMsg && (
        <BasicModal
          img={<img width={"79px"} height={"79px"} src={scanMsg} />}
          text='Oops, You have collected that QR code before'
        />
      )}
      <div className={styles["nav-bar"]}>
        <DrawerComponent />

        <div className={styles["div-rounded"]}>Score: {score}</div>
      </div>
      <div style={{ textAlign: "center" }}>
        <img src={QRcode} style={{marginBottom: 25}} />
        <h3>{remaining} QR Codes left</h3>
      </div>
      <span className={styles["qr-span"]}>
        There are 7 QR codes that are hidden in the building, Try to find them
        all and answer the questions
      </span>
      {!msg ? (
        <ButtonRounded
          value={true}
          text={"Scan"}
          onClick={() => {
            setOpenQr(true);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Scan;
